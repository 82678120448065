const subConfig =
  REACT_APP_ENV === 'live'
    ? {
        appId: '85e80adc3795147182c681ce7e69cbb9',
        secretKey: 'debd7036cbd4a88c1dd0f5cb83c0e832',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAssAOqkyTje' +
          'H2A5OJBQducDPKC/J06hSraWQDvVtlVCPAqa8Jqnsv1x5Kb4i69WM7' +
          'GAis/EsjPYKEyxy6JEcCiZPqDpK2Poi8CwOvknD+reSQ9aCbqWkGPi' +
          'eRc8mYq8LgTzZtp0B2iiv4IcYHCWLdj/5lUIMmLe7fdMP9wo5+W3Xl' +
          'pBAcHyH+VJ3xzq/AaSR1JM4XEvQSLoMSnI2SW9Tusl3feUEB/iJVpu' +
          'oZ7lm9zWwcHmrHhCCuXuxdAoz6tG4jFBa2qK/OaI/EYzftBBsjdaH0' +
          'Od3IZUHfSJ2GHhzo1CEXONs8PpM1m47yaNVHhhMS/TZRR1url4/pR0' +
          'DwA7xFAwIDAQAB' +
          '-----END PUBLIC KEY-----',
      }
    : {
        appId: 'abf227432f9935a0a0dad51c5338a825',
        secretKey: '36f752f5c3733f83e3b0484c340bfd90',
        publicKey:
          '-----BEGIN PUBLIC KEY-----' +
          'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArnOzprxuryHHITI9oAL4' +
          '7FERvLyX5wPbE5Qy0q4vwnlj53zvAWXFfJ56/HJ9uoUMMu3Nz20SKt2vkXZ+1bcT' +
          'u3gUVnLZt+8c1Qmt2x5UFSj+Ip9Muc0CFr16ZYx9FMitabZO3dbe0qUH1+1x04Pf' +
          'pHDw2yCF4KpLTDfMmPBbmbs85RG1X6a0TrTpaFmvM+kl8Xr/yf3pamnGEaDNIBnL' +
          'WfR1er308DBXETOef3enx2/derbLJiQsWNQ3xWZZmhcUzRA6CuES/yZPndsmSIJp' +
          'CSxqPKqhibfVFacHYfXJ2PzDzVXM9SBp5lLZLkRI5L0rLq8CPQ8lULFDnvV0KmF9' +
          'WQIDAQAB' +
          '-----END PUBLIC KEY-----',
      };

const envConfig = (() => {
  // mock, dev, test1, reg
  if (['mock', 'reg'].indexOf(REACT_APP_ENV) >= 0) {
    let env = 'env3';
    switch (REACT_APP_ENV) {
      case 'reg':
        env = 'reg';
        break;
      case 'mock':
      default:
        env = 'env3';
        break;
    }
    // const env = REACT_APP_ENV === 'test1' ? 'env1' : 'env3';
    return {
      iconPreUrl: `https://fastescrow-h5-${env}.test.scredit.com.my`,
    };
  }

  if (REACT_APP_ENV === 'test1') {
    // staging
    return {
      iconPreUrl: `https://fastescrow-h5.staging.scredit.com.my`,
    };
  }

  // live
  if (REACT_APP_ENV === 'live') {
    return {
      iconPreUrl: `https://cdn.scredit.sg/${BIZ_COUNTRY}/fastescrow-h5/live`,
    };
  }

  // stable&dev
  if (['dev', 'stable'].includes(REACT_APP_ENV)) {
    return {
      iconPreUrl: `https://fastescrow-h5.test-stable.scredit.com.my`,
    };
  }

  // test, uat
  return {
    iconPreUrl: `https://fastescrow-h5.${REACT_APP_ENV}.scredit.com.my`,
  };
})();

const requestHeaderConfig = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
    case 'stable':
    case 'test':
    case 'reg':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTM3Nzk4NTQ5LCJqdGkiOiIxOTU0MGI3ZS0zMzhiLTQ3ZWQtYWM0Ni1jOWJjODI1ZGEwZjQiLCJpYXQiOjE2MjI0Mzg1NDksImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.g4UK07Z3yWwD3C9kP00hpykNQs81ku9yAF7veWkULwyNy2DueE6Ebfa02TM-aSUpQON0B9zaDZ31WnIk-RpABg',
      };
    case 'test1': // 映射成staging
    case 'uat':
    case 'staging':
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTM3Nzk4OTg2LCJqdGkiOiIyMTkxOWQzZS0yNjllLTRmNTMtYjgwOC1hY2I5ZTJiNTg3MjkiLCJpYXQiOjE2MjI0Mzg5ODYsImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.7fYzCLWqcv4k94_OLKiig41-rKI60gVAfeXgUQQhNYBWlT3rYxMCrnzQAiHfud282MoePhOK-99FlywifLtgKw',
      };
    case 'live':
    default:
      return {
        Authorization:
          'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIxIiwiZXhwIjoxOTM3Nzk5MTQ3LCJqdGkiOiIwZDIyNzhiNi04MTg1LTQxN2MtYjIxMy1jMTAyOWQ5YjdlMDIiLCJpYXQiOjE2MjI0MzkxNDcsImlzcyI6ImZhc3QtZXNjcm93LWlkLWdhdGV3YXkiLCJzdWIiOiJ7XCJTb3VyY2VcIjoxfSJ9.zib9EaFPXYDKI0PNe_4wauHSvo0Q9eY5wOPv724TRCVCoVpZzSm2djYRSP_z8Mx7apBUqpDSO-uQJ-r9N_eeJg',
      };
  }
})();

const dependDomain = (() => {
  switch (REACT_APP_ENV) {
    case 'mock':
    case 'dev':
      return {
        // ucDomain: 'https://uc-h5-env3.test.scredit.com.my',
        // financeDomain: 'https://finance-env3.test.scredit.com.my',
        ucDomain: 'https://uc-h5.test-stable.scredit.com.my',
        financeDomain: 'https://finance.test-stable.scredit.com.my',
      };
    // TODO: need to update
    case 'stable':
      return {
        ucDomain: 'https://uc-h5.test-stable.scredit.com.my',
        financeDomain: 'https://finance.test-stable.scredit.com.my',
      };
    case 'test':
      return {
        ucDomain: 'https://uc-h5.test.scredit.com.my',
        financeDomain: 'https://finance.test.scredit.com.my',
      };
    case 'test1':
      return {
        ucDomain: 'https://uc-h5.staging.scredit.com.my',
        financeDomain: 'https://finance.staging.scredit.com.my',
      };
    case 'reg':
      return {
        ucDomain: 'https://uc-h5-reg.test.scredit.com.my',
        financeDomain: 'https://finance-reg.test.scredit.com.my',
      };
    case 'uat':
      return {
        ucDomain: 'https://uc-h5.uat.scredit.com.my',
        financeDomain: 'https://finance.uat.scredit.com.my',
      };
    case 'live':
    default:
      return {
        ucDomain: 'https://uc-h5.scredit.com.my',
        financeDomain: 'https://finance.scredit.com.my',
      };
  }
})();

const config = {
  platForm: 1,
  version: 1,
  region: 'my',
  fastEscrowVersion: 'fastescrow_v1.0.0.100',
  ...subConfig,
  ...envConfig,
  ...requestHeaderConfig,
  ...dependDomain,
};

export default config;
